import React from 'react';
import { useLocation } from 'react-router-dom';
import '../App.css';

const NoMatch = () => {
  const loc = useLocation();
  return (
    <div className="App">
      <header className="App-header">
        <h2>404</h2>
        <p>Page <code>{loc.pathname}</code> not found.</p>
      </header>
    </div>
  );
};

export default NoMatch;
