import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import {
  Container, Box, Card, CardContent, Typography
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ReactGA from 'react-ga';
import LawListing from '../../components/lawListing';
import '../App.css';

const Home = () => {
  useEffect(() => ReactGA.pageview(window.location.pathname + window.location.search), []);
  const { isLoading, data } = useQuery('homepageData', () => fetch('https://backend.globalgovwatch.com/api/homepage_laws.json').then((res) => res.json().then((jdata) => jdata)));

  useEffect(() => {
    document.title = 'GlobalGovWatch';
  }, []);

  const loadingSkeleton = [];
  for (let i = 0; i < 10; i++) {
    loadingSkeleton.push(
      <Card style={{ marginTop: '8px' }} key={`card-${i}`}>
        <CardContent>
          <Skeleton animation="wave" variant="text" />
        </CardContent>
      </Card>
    );
  }

  return (
    <Container>
      <Box style={{ marginTop: '16px' }}>
        <Typography variant="h4">Latest laws:</Typography>
        {isLoading && loadingSkeleton}
        {!isLoading
        && data.map((law) => (
          <LawListing key={law.ID} law={law} />
        ))}
      </Box>
    </Container>
  );
};

export default Home;
