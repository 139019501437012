import React, { useEffect } from 'react';
import {
  Container, Box, Typography, Paper
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import ReactGA from 'react-ga';
import '../App.css';

const Law = () => {
  useEffect(() => ReactGA.pageview(window.location.pathname + window.location.search), []);

  const { uuid } = useParams();
  const { isLoading, data } = useQuery(['law', uuid], () => fetch(`https://backend.globalgovwatch.com/api/law.json?id=${uuid}`).then((res) => res.json().then((jdata) => jdata)));

  const law = data?.data;
  const success = data?.success;
  const translated = law?.translated === '1';

  let title = 'Loading law...';

  if (!isLoading && success) {
    title = translated ? `Machine Translation of ${law.title_translated}` : law.title;
  }

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Container>
      {isLoading
      && (
        <Paper>
          <Box m={4}>
            <br />
            <Skeleton animation="wave" variant="rect" height={34} />
            <br />
            <Skeleton animation="wave" variant="text" />
            <Skeleton animation="wave" variant="text" />
            <Skeleton animation="wave" variant="text" />
            <Skeleton animation="wave" variant="text" />
            <Skeleton animation="wave" variant="text" />
            <br />
            <Skeleton animation="wave" variant="text" />
            <Skeleton animation="wave" variant="text" />
            <Skeleton animation="wave" variant="text" />
            <Skeleton animation="wave" variant="text" />
            <Skeleton animation="wave" variant="text" />
            <br />
          </Box>
        </Paper>
      )}
      {(!isLoading && success)
      && (
        <Paper>
          <Box m={4}>
            <br />
            <Typography variant="h4" m={2}>{title}</Typography>
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: (translated ? law.text_translated : law.text) }} />
          </Box>
        </Paper>
      )}
      {(!isLoading && !success)
      && (
        <Paper>
          <Box m={4}>
            <br />
            <Typography variant="h4" m={2}>Law not found in our database. It may have been removed or you may have followed a broken link.</Typography>
          </Box>
        </Paper>
      )}
    </Container>
  );
};

export default Law;
