import React from 'react';
import Flags from 'country-flag-icons/react/3x2';

const WIDTH = 32;
const flags = {
  /* abkhazia: <Flags.AB title="Abkhazia" width={WIDTH} />, */
  'ascension island': <Flags.AC title="Ascension Island" width={WIDTH} />,
  andorra: <Flags.AD title="Andorra" width={WIDTH} />,
  'united arab emirates': <Flags.AE title="United Arab Emirates" width={WIDTH} />,
  afghanistan: <Flags.AF title="Afghanistan" width={WIDTH} />,
  'antigua and barbuda': <Flags.AG title="Antigua and Barbuda" width={WIDTH} />,
  anguilla: <Flags.AI title="Anguilla" width={WIDTH} />,
  albania: <Flags.AL title="Albania" width={WIDTH} />,
  armenia: <Flags.AM title="Armenia" width={WIDTH} />,
  angola: <Flags.AO title="Angola" width={WIDTH} />,
  antarctica: <Flags.AQ title="Antarctica" width={WIDTH} />,
  argentina: <Flags.AR title="Argentina" width={WIDTH} />,
  'american samoa': <Flags.AS title="American Samoa" width={WIDTH} />,
  austria: <Flags.AT title="Austria" width={WIDTH} />,
  australia: <Flags.AU title="Australia" width={WIDTH} />,
  aruba: <Flags.AW title="Aruba" width={WIDTH} />,
  'aland islands': <Flags.AX title="Åland Islands" width={WIDTH} />,
  azerbaijan: <Flags.AZ title="Azerbaijan" width={WIDTH} />,
  'bosnia and herzegovina': <Flags.BA title="Bosnia and Herzegovina" width={WIDTH} />,
  barbados: <Flags.BB title="Barbados" width={WIDTH} />,
  bangladesh: <Flags.BD title="Bangladesh" width={WIDTH} />,
  belgium: <Flags.BE title="Belgium" width={WIDTH} />,
  'burkina faso': <Flags.BF title="Burkina Faso" width={WIDTH} />,
  bulgaria: <Flags.BG title="Bulgaria" width={WIDTH} />,
  bahrain: <Flags.BH title="Bahrain" width={WIDTH} />,
  burundi: <Flags.BI title="Burundi" width={WIDTH} />,
  benin: <Flags.BJ title="Benin" width={WIDTH} />,
  'saint barthelemy': <Flags.BL title="Saint Barthélemy" width={WIDTH} />,
  bermuda: <Flags.BM title="Bermuda" width={WIDTH} />,
  brunei: <Flags.BN title="Brunei Darussalam" width={WIDTH} />,
  bolivia: <Flags.BO title="Bolivia" width={WIDTH} />,
  bonaire: <Flags.BQ title="Bonaire, Sint Eustantius and Saba" width={WIDTH} />,
  brazil: <Flags.BR title="Brazil" width={WIDTH} />,
  bahamas: <Flags.BS title="Bahamas" width={WIDTH} />,
  bhutan: <Flags.BT title="Bhutan" width={WIDTH} />,
  'bouvet island': <Flags.BV title="Bouvet Island" width={WIDTH} />,
  botswana: <Flags.BW title="Botswana" width={WIDTH} />,
  belarus: <Flags.BY title="Belarus" width={WIDTH} />,
  belize: <Flags.BZ title="Belize" width={WIDTH} />,
  canada: <Flags.CA title="Canada" width={WIDTH} />,
  'cocos islands': <Flags.CC title="Cocos (Keeling) Islands" width={WIDTH} />,
  'congo, democratic republic of the': <Flags.CD title="Congo, Democratic Republic of the" width={WIDTH} />,
  'central african republic': <Flags.CF title="Central African Republic" width={WIDTH} />,
  congo: <Flags.CG title="Congo" width={WIDTH} />,
  switzerland: <Flags.CH title="Switzerland" width={WIDTH} />,
  'cote d\'Ivoire': <Flags.CI title="Cote d'Ivoire" width={WIDTH} />,
  'cook islands': <Flags.CK title="Cook Islands" width={WIDTH} />,
  chile: <Flags.CL title="Chile" width={WIDTH} />,
  cameroon: <Flags.CM title="Cameroon" width={WIDTH} />,
  china: <Flags.CN title="China" width={WIDTH} />,
  colombia: <Flags.CO title="Colombia" width={WIDTH} />,
  'costa rica': <Flags.CR title="Costa Rica" width={WIDTH} />,
  cuba: <Flags.CU title="Cuba" width={WIDTH} />,
  'cape verde': <Flags.CV title="Cape Verde" width={WIDTH} />,
  curacao: <Flags.CW title="Curaçao" width={WIDTH} />,
  'christmas island': <Flags.CX title="Christmas Island" width={WIDTH} />,
  cyprus: <Flags.CY title="Cyprus" width={WIDTH} />,
  'czech republic': <Flags.CZ title="Czech Republic" width={WIDTH} />,
  germany: <Flags.DE title="Germany" width={WIDTH} />,
  djibouti: <Flags.DJ title="Djibouti" width={WIDTH} />,
  denmark: <Flags.DK title="Denmark" width={WIDTH} />,
  dominica: <Flags.DM title="Dominica" width={WIDTH} />,
  'dominican republic': <Flags.DO title="Dominican Republic" width={WIDTH} />,
  algeria: <Flags.DZ title="Algeria" width={WIDTH} />,
  ecuador: <Flags.EC title="Ecuador" width={WIDTH} />,
  estonia: <Flags.EE title="Estonia" width={WIDTH} />,
  egypt: <Flags.EG title="Egypt" width={WIDTH} />,
  'western sahara': <Flags.EH title="Western Sahara" width={WIDTH} />,
  eritrea: <Flags.ER title="Eritrea" width={WIDTH} />,
  spain: <Flags.ES title="Spain" width={WIDTH} />,
  ethiopia: <Flags.ET title="Ethiopia" width={WIDTH} />,
  finland: <Flags.FI title="Finland" width={WIDTH} />,
  fiji: <Flags.FJ title="Fiji" width={WIDTH} />,
  'falklands islands': <Flags.FK title="Falklands Islands" width={WIDTH} />,
  micronesia: <Flags.FM title="Federated States of Micronesia" width={WIDTH} />,
  'faroe islands': <Flags.FO title="Faroe Islands" width={WIDTH} />,
  france: <Flags.FR title="France" width={WIDTH} />,
  gabon: <Flags.GA title="Gabon" width={WIDTH} />,
  'united kingdom': <Flags.GB title="United Kingdom" width={WIDTH} />,
  grenada: <Flags.GD title="Grenada" width={WIDTH} />,
  georgia: <Flags.GE title="Georgia" width={WIDTH} />,
  'french guiana': <Flags.GF title="French Guiana" width={WIDTH} />,
  guernsey: <Flags.GG title="Guernsey" width={WIDTH} />,
  ghana: <Flags.GH title="Ghana" width={WIDTH} />,
  gibraltar: <Flags.GI title="Gibraltar" width={WIDTH} />,
  greenland: <Flags.GL title="Greenland" width={WIDTH} />,
  gambia: <Flags.GM title="Gambia" width={WIDTH} />,
  guinea: <Flags.GN title="Guinea" width={WIDTH} />,
  guadeloupe: <Flags.GP title="Guadeloupe" width={WIDTH} />,
  'equatorial guinea': <Flags.GQ title="Equatorial Guinea" width={WIDTH} />,
  greece: <Flags.GR title="Greece" width={WIDTH} />,
  'south georgia': <Flags.GS title="South Georgia and the South Sandwich Islands" width={WIDTH} />,
  guatemala: <Flags.GT title="Guatemala" width={WIDTH} />,
  guam: <Flags.GU title="Guam" width={WIDTH} />,
  'guinea-bissau': <Flags.GW title="Guinea-Bissau" width={WIDTH} />,
  guyana: <Flags.GY title="Guyana" width={WIDTH} />,
  'hong kong': <Flags.HK title="Hong Kong" width={WIDTH} />,
  'heard island and mcdonald islands': <Flags.HM title="Heard Island and McDonald Islands" width={WIDTH} />,
  honduras: <Flags.HN title="Honduras" width={WIDTH} />,
  croatia: <Flags.HR title="Croatia" width={WIDTH} />,
  haiti: <Flags.HT title="Haiti" width={WIDTH} />,
  hungary: <Flags.HU title="Hungary" width={WIDTH} />,
  indonesia: <Flags.ID title="Indonesia" width={WIDTH} />,
  ireland: <Flags.IE title="Ireland" width={WIDTH} />,
  israel: <Flags.IL title="Israel" width={WIDTH} />,
  'isle of man': <Flags.IM title="Isle of Man" width={WIDTH} />,
  india: <Flags.IN title="India" width={WIDTH} />,
  'british indian ocean territory': <Flags.IO title="British Indian Ocean Territory" width={WIDTH} />,
  iraq: <Flags.IQ title="Iraq" width={WIDTH} />,
  iran: <Flags.IR title="Iran" width={WIDTH} />,
  iceland: <Flags.IS title="Iceland" width={WIDTH} />,
  italy: <Flags.IT title="Italy" width={WIDTH} />,
  jersey: <Flags.JE title="Jersey" width={WIDTH} />,
  jamaica: <Flags.JM title="Jamaica" width={WIDTH} />,
  jordan: <Flags.JO title="Jordan" width={WIDTH} />,
  japan: <Flags.JP title="Japan" width={WIDTH} />,
  kenya: <Flags.KE title="Kenya" width={WIDTH} />,
  kyrgyzstan: <Flags.KG title="Kyrgyzstan" width={WIDTH} />,
  cambodia: <Flags.KH title="Cambodia" width={WIDTH} />,
  kiribati: <Flags.KI title="Kiribati" width={WIDTH} />,
  comoros: <Flags.KM title="Comoros" width={WIDTH} />,
  'saint kitts and nevis': <Flags.KN title="Saint Kitts and Nevis" width={WIDTH} />,
  'north korea': <Flags.KP title="North Korea" width={WIDTH} />,
  'south korea': <Flags.KR title="South Korea" width={WIDTH} />,
  kuwait: <Flags.KW title="Kuwait" width={WIDTH} />,
  'cayman islands': <Flags.KY title="Cayman Islands" width={WIDTH} />,
  kazakhstan: <Flags.KZ title="Kazakhstan" width={WIDTH} />,
  laos: <Flags.LA title="Laos" width={WIDTH} />,
  lebanon: <Flags.LB title="Lebanon" width={WIDTH} />,
  'saint lucia': <Flags.LC title="Saint Lucia" width={WIDTH} />,
  liechtenstein: <Flags.LI title="Liechtenstein" width={WIDTH} />,
  'sri lanka': <Flags.LK title="Sri Lanka" width={WIDTH} />,
  liberia: <Flags.LR title="Liberia" width={WIDTH} />,
  lesotho: <Flags.LS title="Lesotho" width={WIDTH} />,
  lithuania: <Flags.LT title="Lithuania" width={WIDTH} />,
  luxembourg: <Flags.LU title="Luxembourg" width={WIDTH} />,
  latvia: <Flags.LV title="Latvia" width={WIDTH} />,
  libya: <Flags.LY title="Libya" width={WIDTH} />,
  morocco: <Flags.MA title="Morocco" width={WIDTH} />,
  monaco: <Flags.MC title="Monaco" width={WIDTH} />,
  moldova: <Flags.MD title="Moldova" width={WIDTH} />,
  montenegro: <Flags.ME title="Montenegro" width={WIDTH} />,
  'saint martin (french part)': <Flags.MF title="Saint Martin (French Part)" width={WIDTH} />,
  madagascar: <Flags.MG title="Madagascar" width={WIDTH} />,
  'marshall islands': <Flags.MH title="Marshall Islands" width={WIDTH} />,
  'north macedonia': <Flags.MK title="North Macedonia" width={WIDTH} />,
  mali: <Flags.ML title="Mali" width={WIDTH} />,
  burma: <Flags.MM title="Burma" width={WIDTH} />,
  mongolia: <Flags.MN title="Mongolia" width={WIDTH} />,
  macao: <Flags.MO title="Macao" width={WIDTH} />,
  'northern mariana islands': <Flags.MP title="Northern Mariana Islands" width={WIDTH} />,
  martinique: <Flags.MQ title="Martinique" width={WIDTH} />,
  mauritania: <Flags.MR title="Mauritania" width={WIDTH} />,
  monteserrat: <Flags.MS title="Monteserrat" width={WIDTH} />,
  malta: <Flags.MT title="Malta" width={WIDTH} />,
  mauritius: <Flags.MU title="Mauritius" width={WIDTH} />,
  maldives: <Flags.MV title="Maldives" width={WIDTH} />,
  malawi: <Flags.MW title="Malawi" width={WIDTH} />,
  mexico: <Flags.MX title="Mexico" width={WIDTH} />,
  malaysia: <Flags.MY title="Malaysia" width={WIDTH} />,
  mozambique: <Flags.MZ title="Mozambique" width={WIDTH} />,
  namibia: <Flags.NA title="Namibia" width={WIDTH} />,
  'new caledonia': <Flags.NC title="New Caledonia" width={WIDTH} />,
  niger: <Flags.NE title="Niger" width={WIDTH} />,
  'norfolk island': <Flags.NF title="Norfolk Island" width={WIDTH} />,
  nigeria: <Flags.NG title="Nigeria" width={WIDTH} />,
  nicaragua: <Flags.NI title="Nicaragua" width={WIDTH} />,
  netherlands: <Flags.NL title="Netherlands" width={WIDTH} />,
  norway: <Flags.NO title="Norway" width={WIDTH} />,
  nepal: <Flags.NP title="Nepal" width={WIDTH} />,
  nauru: <Flags.NR title="Nauru" width={WIDTH} />,
  niue: <Flags.NU title="Niue" width={WIDTH} />,
  'new zealand': <Flags.NZ title="New Zealand" width={WIDTH} />,
  oman: <Flags.OM title="Oman" width={WIDTH} />,
  /* 'south ossetia': <Flags.OS title="South Ossetia" width={WIDTH} />, */
  panama: <Flags.PA title="Panama" width={WIDTH} />,
  peru: <Flags.PE title="Peru" width={WIDTH} />,
  'french polynesia': <Flags.PF title="French Polynesia" width={WIDTH} />,
  'papua new guinea': <Flags.PG title="Papua New Guinea" width={WIDTH} />,
  philippines: <Flags.PH title="Philippines" width={WIDTH} />,
  pakistan: <Flags.PK title="Pakistan" width={WIDTH} />,
  poland: <Flags.PL title="Poland" width={WIDTH} />,
  'saint pierre and miquelon': <Flags.PM title="Saint Pierre and Miquelon" width={WIDTH} />,
  pitcairn: <Flags.PN title="Pitcairn" width={WIDTH} />,
  'puerto rico': <Flags.PR title="Puerto Rico" width={WIDTH} />,
  palestine: <Flags.PS title="Palestine" width={WIDTH} />,
  portugal: <Flags.PT title="Portugal" width={WIDTH} />,
  palau: <Flags.PW title="Palau" width={WIDTH} />,
  paraguay: <Flags.PY title="Paraguay" width={WIDTH} />,
  qatar: <Flags.QA title="Qatar" width={WIDTH} />,
  reunion: <Flags.RE title="Reunion" width={WIDTH} />,
  romania: <Flags.RO title="Romania" width={WIDTH} />,
  serbia: <Flags.RS title="Serbia" width={WIDTH} />,
  russia: <Flags.RU title="Russia" width={WIDTH} />,
  rwanda: <Flags.RW title="Rwanda" width={WIDTH} />,
  'saudia arabia': <Flags.SA title="Saudi Arabia" width={WIDTH} />,
  'solomon islands': <Flags.SB title="Solomon Islands" width={WIDTH} />,
  seychelles: <Flags.SC title="Seychelles" width={WIDTH} />,
  sudan: <Flags.SD title="Sudan" width={WIDTH} />,
  sweden: <Flags.SE title="Sweden" width={WIDTH} />,
  singapore: <Flags.SG title="Singapore" width={WIDTH} />,
  'saint helena': <Flags.SH title="Saint Helena" width={WIDTH} />,
  slovenia: <Flags.SI title="Slovenia" width={WIDTH} />,
  'svalbard and jan mayen': <Flags.SJ title="Svalbard and Jan Mayen" width={WIDTH} />,
  slovakia: <Flags.SK title="Slovakia" width={WIDTH} />,
  'sierra leone': <Flags.SL title="Sierra Leone" width={WIDTH} />,
  'san marino': <Flags.SM title="San Marino" width={WIDTH} />,
  senegal: <Flags.SN title="Senegal" width={WIDTH} />,
  somalia: <Flags.SO title="Somalia" width={WIDTH} />,
  suriname: <Flags.SR title="Suriname" width={WIDTH} />,
  'south sudan': <Flags.SS title="South Sudan" width={WIDTH} />,
  'sao tome and pricipe': <Flags.ST title="Sao Tome and Pricipe" width={WIDTH} />,
  'el salvador': <Flags.SV title="El Salvador" width={WIDTH} />,
  'sint maarten': <Flags.SX title="Sint Maarten" width={WIDTH} />,
  syria: <Flags.SY title="Syria" width={WIDTH} />,
  swaziland: <Flags.SZ title="Swaziland" width={WIDTH} />,
  'tristan da cunha': <Flags.TA title="Tristan da Cunha" width={WIDTH} />,
  'turks and caicos islands': <Flags.TC title="Turks and Caicos Islands" width={WIDTH} />,
  chad: <Flags.TD title="Chad" width={WIDTH} />,
  'french southern territories': <Flags.TF title="French Southern Territories" width={WIDTH} />,
  togo: <Flags.TG title="Togo" width={WIDTH} />,
  thailand: <Flags.TH title="Thailand" width={WIDTH} />,
  tajikistan: <Flags.TJ title="Tajikistan" width={WIDTH} />,
  tokelau: <Flags.TK title="Tokelau" width={WIDTH} />,
  'timor-leste': <Flags.TL title="Timor-Leste" width={WIDTH} />,
  turkmenistan: <Flags.TM title="Turkmenistan" width={WIDTH} />,
  tunisia: <Flags.TN title="Tunisia" width={WIDTH} />,
  tonga: <Flags.TO title="Tonga" width={WIDTH} />,
  turkey: <Flags.TR title="Turkey" width={WIDTH} />,
  'trinidad and tobago': <Flags.TT title="Trinidad and Tobago" width={WIDTH} />,
  tuvalu: <Flags.TV title="Tuvalu" width={WIDTH} />,
  taiwan: <Flags.TW title="Taiwan" width={WIDTH} />,
  tanzania: <Flags.TZ title="Tanzania" width={WIDTH} />,
  ukraine: <Flags.UA title="Ukraine" width={WIDTH} />,
  uganda: <Flags.UG title="Uganda" width={WIDTH} />,
  /* 'united states minor outlying islands': <Flags.UM title="United States Minor Outlying Islands" width={WIDTH} />, */
  'united states': <Flags.US title="United States" width={WIDTH} />,
  uruguay: <Flags.UY title="Uruguay" width={WIDTH} />,
  uzbekistan: <Flags.UZ title="Uzbekistan" width={WIDTH} />,
  'vatican city': <Flags.VA title="Holy See (Vatican City State)" width={WIDTH} />,
  'saint vincent and the grenadines': <Flags.VC title="Saint Vincent and the Grenadines" width={WIDTH} />,
  venezuela: <Flags.VE title="Venezuela" width={WIDTH} />,
  'virgin islands, british': <Flags.VG title="Virgin Islands, British" width={WIDTH} />,
  'virgin islands, u.s.': <Flags.VI title="Virgin Islands, U.S." width={WIDTH} />,
  vietnam: <Flags.VN title="Vietnam" width={WIDTH} />,
  vanuatu: <Flags.VU title="Vanuatu" width={WIDTH} />,
  'wallis and futuna': <Flags.WF title="Wallis and Futuna" width={WIDTH} />,
  samoa: <Flags.WS title="Samoa" width={WIDTH} />,
  kosovo: <Flags.XK title="Kosovo" width={WIDTH} />,
  yemen: <Flags.YE title="Yemen" width={WIDTH} />,
  mayotte: <Flags.YT title="Mayotte" width={WIDTH} />,
  'south africa': <Flags.ZA title="South Africa" width={WIDTH} />,
  zambia: <Flags.ZM title="Zambia" width={WIDTH} />,
  zimbabwe: <Flags.ZW title="Zimbabwe" width={WIDTH} />
};

const FlagIcon = ({ country }) => {
  const c = country?.toLowerCase();

  if (flags[c]) return flags[c];

  return null;
};

export default FlagIcon;
