import React from 'react';
import {
  Card, CardContent, Typography, Link, Grid
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import FlagIcon from './flagIcon';

const LawListing = ({ law }) => {
  return (
    <Link underline="none" to={`/law/${law.country}/${law.uuid}/${law.title}`} component={RouterLink}>
      <Card style={{ marginTop: '8px' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <FlagIcon country={law.country} />
            </Grid>
            <Grid item xs={11}>
              <Typography>
                {law.title_translated ?? law.title}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  );
};

export default LawListing;
