import React from 'react';
import ReactDOM from 'react-dom';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import ReactGA from 'react-ga';
import './index.css';
import App from './pages';
import * as serviceWorker from './serviceWorker';

// Init Google Analytics:
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // Development
  ReactGA.initialize('UA-126198832-2', { testMode: true });
}
else {
  // Production:
  ReactGA.initialize('UA-126198832-2');
}

const queryCache = new QueryCache();

ReactDOM.render(
  <React.StrictMode>
    <ReactQueryCacheProvider queryCache={queryCache}>
      <App />
    </ReactQueryCacheProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
