import React from 'react';
import {
  BrowserRouter as Router, Switch, Route, Link
} from 'react-router-dom';
import {
  CssBaseline, AppBar, Toolbar, Typography, Button
} from '@material-ui/core';
import './App.css';
import Home from './home';
import Law from './law';
import NoMatch from './nomatch';

// eslint-disable-next-line arrow-body-style
const App = () => {
  return (
    <>
      <CssBaseline />
      <Router>
        <AppBar position="sticky">
          <Toolbar>
            <Button color="default" component={Link} to="/">
              <Typography variant="h6" style={{ color: 'white' }}>Global Gov Watch</Typography>
            </Button>
          </Toolbar>
        </AppBar>

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/law/:country/:uuid/:title">
            <Law />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
